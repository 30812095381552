import { useEffect, useState, useMemo } from "react"
import { useSwipeable } from "react-swipeable"
import logo from "../../../assets/funki_VAG_1280_red.png"
import { Grid, Stack, useMediaQuery, ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Box } from "@mui/material"
import { SONG_CATEGORIES, SONG_TYPES, Song } from "../../../utils/types"
import SongCard from "../../../components/Card"
import { useCurrentUser } from "../../../hooks/contexts/currentUserContext"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import GridViewIcon from "@mui/icons-material/GridView"
import { useChangeCurrentSong, useCurrentSong } from "../../../hooks/contexts/currentSongContext"

interface DisplayedCards {
    displayedSongs: Song[]
    page: number
    cardsPerPage: number
    viewMode: "grid" | "list"
    changeCurrentSong: (id: string) => void
    currentSong: Song | null | undefined
    type: SONG_TYPES
}

const DisplayedCards = ({ displayedSongs, page, cardsPerPage, viewMode, changeCurrentSong, currentSong, type }: DisplayedCards) => {
    const [currentCards, setCurrentCards] = useState<Song[]>([])
	console.log("CURENT CARDS: ", currentCards)
	console.log(" : ", displayedSongs)

    useEffect(() => {
        const startIndex = (page - 1) * cardsPerPage
        const endIndex = startIndex + cardsPerPage
        const updatedCurrentCards = displayedSongs.slice(startIndex, endIndex)

        setCurrentCards(updatedCurrentCards)
    }, [displayedSongs, page, cardsPerPage])

    return type === SONG_TYPES.KEY ? (
        <KeytoneList currentCards={currentCards} changeCurrentSong={changeCurrentSong} currentSong={currentSong} />
    ) : viewMode === "list" ? (
        <ListView currentCards={currentCards} changeCurrentSong={changeCurrentSong} currentSong={currentSong} />
    ) : (
        <Grid container spacing={3} sx={{ height: "auto", alignItems: "space-between" }}>
            {currentCards.map((song) => (
                <Grid key={song.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <SongCard song={song} currentSong={currentSong} changeCurrentSong={changeCurrentSong} />
                </Grid>
            ))}
        </Grid>
    )
}

interface ListView {
    currentCards: Song[]
    changeCurrentSong: (id: string) => void
    currentSong: Song | null | undefined
}

const ListView = ({ currentCards, changeCurrentSong, currentSong }: ListView) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Namn</TableCell>
                        <TableCell>Producent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentCards.map((song) => {
                        const isSelected = currentSong?.id === song.id
                        return (
                            <TableRow key={song.id} onClick={() => changeCurrentSong(song.id)} sx={{ cursor: "pointer", background: isSelected ? (theme) => theme.palette.success.main : "inherit" }}>
                                <TableCell>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <img
                                            src={song.img ?? logo}
                                            alt={song.title}
                                            style={{ width: 50 }}
                                            onError={(e) => {
                                                e.currentTarget.src = "/favicon.png"
                                            }}
                                        />
                                        {song.title}
                                    </Box>
                                </TableCell>

                                <TableCell>{song.artist}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface ListView {
    currentCards: Song[]
    changeCurrentSong: (id: string) => void
    currentSong: Song | null | undefined
}

const KeytoneList = ({ currentCards, changeCurrentSong, currentSong }: ListView) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Tonart</TableCell>
                        <TableCell>Skala</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentCards.map((song) => {
                        const isSelected = currentSong?.id === song.id
                        return (
                            <TableRow key={song.id} onClick={() => changeCurrentSong(song.id)} sx={{ cursor: "pointer", background: isSelected ? (theme) => theme.palette.success.main : "inherit" }}>
                                <TableCell>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>{song.keytone}</Box>
                                </TableCell>
                                <TableCell>{song.category.charAt(0).toLocaleUpperCase() + song.category.slice(1)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DisplayedCards
