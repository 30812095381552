import React from "react"
import { useState, useEffect } from "react"
import { usePostMqttChangeSong } from "../../../mqtt/mqttHandlerContext"
import { Instrument, Song } from "../../../utils/types"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import Text from "../../../assets/components/Text"
import { formatString } from "../../../utils/formatter"

const getSongID = (currentSong: Song, sound: string, tones?: string) => currentSong.keytone + "-" + currentSong.category.toLowerCase() + "-" + sound
const availableSounds = ["piano", "gitarr", "synth", "bell", "gong", "bright-bell"]
// const touchDifficultLevel = ["synth-easy", "synth-hard", "bell-easy", "bell-hard", "gong-hard"]
const touchDifficultLevel = [
    { id: "synth-easy", name: "Synth Duo" },
    { id: "synth-hard", name: "Synth 12" },
    { id: "bell-easy", name: "Bell Duo" },
    { id: "bell-hard", name: "Bell" },
    { id: "gong-hard", name: "Gong" },
]

const InstrumentSettings = ({ currentSong, selectedInstrument }: { currentSong: Song; selectedInstrument: Instrument }) => {
    const postChangeCong = usePostMqttChangeSong()
    const [soundAlignment, setSoundAlignment] = React.useState<string | null>("1")
    const [valueAlignment, setValueAlignment] = React.useState<string | null>("1")
    const [sound, setSound] = useState<string>("synth")
    const [value, setValue] = useState<string>("3")
    const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false)
    const instrumentId = selectedInstrument.deviceId
    const instrumentType = selectedInstrument.type

    const handleSoundAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setSoundAlignment(newAlignment)
        setHasUserInteracted(true) // Mark that the user has interacted
    }

    const handleValueAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setValueAlignment(newAlignment)
        setHasUserInteracted(true) // Mark that the user has interacted
    }

    const [instrumentSound, setInstrumentSound] = useState<{
        [key: string]: string
    }>({})

    const handleChangeSound = ({ sound }: { sound: string }) => {
        if (!instrumentId) {
            console.error("Instrument ID is undefined")
            return
        }
        setInstrumentSound((prevVolumes) => ({
            ...prevVolumes,
            [instrumentId]: sound,
        }))
        setSound(sound)
        setHasUserInteracted(true)
    }

    const handleChangeValue = ({ value }: { value: string }) => {
        setValue(value)
        setHasUserInteracted(true)
    }

    useEffect(() => {
        if (hasUserInteracted) {
            postChangeCong(getSongID(currentSong, sound), instrumentId)
        }
    }, [sound, value, hasUserInteracted]) // Add hasUserInteracted to dependencies

    // Fetch initial settings values from storage when the component mounts
    useEffect(() => {
        const storedSoundString = localStorage.getItem("instrumentSound")
        if (storedSoundString) {
            const storedSound = JSON.parse(storedSoundString)
            setSound(storedSound)
        }
    }, [])

    // Save setting values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentSound", JSON.stringify(instrumentSound))
    }, [instrumentSound])

    return (
        <Box>
            <Text size={"heading"}>Sound</Text>
            {instrumentType === "touch" ? (
                <ToggleButtonGroup value={instrumentSound[instrumentId] ?? ""} exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment">
                    {touchDifficultLevel.map((sound) => (
                        <ToggleButton key={sound.id} value={sound.id} aria-label={sound.name} onClick={() => handleChangeSound({ sound: sound.id })}>
                            {formatString(sound.name)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            ) : (
                <ToggleButtonGroup value={instrumentSound[instrumentId] ?? ""} exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment">
                    {availableSounds.map((sound: string) => (
                        <ToggleButton key={sound} value={sound} aria-label={sound} onClick={() => handleChangeSound({ sound: sound })}>
                            {formatString(sound)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </Box>
    )
}

export default InstrumentSettings
